<template>
  <div>
    <!-- 第三方图标配置 start -->
    <div style="background: #D4D4D4" marginwidth="100px">
      <div class="category-container ng-star-inserted">
        <div class="icon-item-container active" v-for="(item, index) in iconDataList" :key="index">
          <div class="icon-item-container active" :title="item.iconClass">
            <div class="icon-item-inner-container" @click="iconClick($event, index)">
              <span class="icon-image-preview material-icons">{{ item.iconClass }}</span>
              <span class="icon-item-title">{{ item.iconName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三方图标配置 end -->
  </div>
</template>
<style scoped>
/*第三方图标 start*/
.icon-item-container {
  width: 182px;
  padding-top: 10px;
  display: inline-block;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.icon-image-preview {
  display: inline-block;
  font-size: 34px !important;
  overflow: hidden;
}

.icon-item-container:hover {
  color: #5994ff;
}

.icon-item-title {
  line-height: 30px;
  position: relative;
  top: -12px;
  left: 5px;
}

.category-name {
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 18px;
  margin-top: 0;
}

.category-container {
  background: #fff;
  padding: 20px;
  padding-left: 30px;
  padding-top: 10px;
}

article {
  padding: 12px;
}

article .header-left-color {
  margin-left: -26px !important;
  border-left: 5px solid #7e9df5;
  padding-left: 18px;
  font-size: 25px;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
/*第三方图标 end*/
</style>

<script>
import iconJson from "./icon.json";
export default {
  name: "iconList",
  data() {
    return {
      active: "",
      iconDataList: [],
      iconClass: "",
    };
  },
  created() {
    iconJson.forEach((element) => {
      if (element.iconName.length > 15) 
        element.iconName = element.iconName.substring(0, 13) + "...";
    });
    this.iconDataList = iconJson;
  },
  methods: {
    iconClick(event, index) {
      this.iconClass = this.iconDataList[index].iconClass;
      this.$emit("mainIconClick", this.iconClass);
    },
    /*针对element ui icon 鼠标悬浮背景色设置 start*/
    mouseEnter(item) {
      this.$set(item, "mouseFlag", true);
    },
    mouseLeave(item) {
      this.$set(item, "mouseFlag", false);
    },
    /*针对element ui icon 鼠标悬浮背景色设置 end*/
  },
};
</script>

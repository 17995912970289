<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button
          type="primary"
          @click="
            dialogVisible = true;
            fileList = [];
          "
          >新增</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      stripe
      lazy
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="名称" sortable width="180">
      </el-table-column>
      <el-table-column prop="perms" label="权限编码" sortable width="180">
      </el-table-column>

      <el-table-column prop="icon" label="图标"> </el-table-column>

      <el-table-column label="背景"
        ><template slot-scope="scope">
          {{ scope.row.blackgroundColor || scope.row.blackgroundImg }}
        </template></el-table-column
      >

      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === 0">目录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 1" type="success"
            >菜单</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.type === 2" type="info"
            >按钮</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column prop="path" label="菜单URL"> </el-table-column>
      <el-table-column prop="component" label="菜单组件"> </el-table-column>
      <el-table-column prop="orderNum" label="排序号"> </el-table-column>
      <el-table-column prop="statu" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="delHandle(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!--新增对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="上级菜单" prop="parentId">
          <el-select v-model="editForm.parentId" placeholder="请选择上级菜单">
            <template v-for="item in tableData">
              <el-option :label="item.name" :value="item.id" :key="item.id"></el-option>
              <template v-for="child in item.children">
                <el-option :label="child.name" :value="child.id" :key="child.id">
                  <span>{{ "- " + child.name }}</span>
                </el-option>
              </template>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="菜单名称" prop="name" label-width="100px">
          <el-input v-model="editForm.name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="权限编码" prop="perms" label-width="100px">
          <el-input v-model="editForm.perms" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="图标" prop="icon" label-width="100px">
          <el-input
            v-model="editForm.icon"
            autocomplete="off"
            @click.stop.native="herfIconUrl"
          ></el-input>
        </el-form-item>
        <el-form-item label="背景类型" label-width="100px">
          <el-switch
            v-model="backgroundType"
            active-text="背景色"
            inactive-text="背景图"
            active-color="#227604"
            inactive-color="#055FBC"
          >
          </el-switch>
        </el-form-item>

        <el-form-item
          v-if="backgroundType"
          label="背景色"
          prop="blackgroundColor"
          label-width="100px"
        >
          <div class="block">
            <el-color-picker
              v-model="editForm.blackgroundColor"
            ></el-color-picker>
          </div>
        </el-form-item>
        <div v-if="!backgroundType">
          <el-form-item label="背景图" label-width="100px">
            <el-row>
              <el-col :span="12">
                <el-upload
                  action
                  ref="upload"
                  :before-remove="beforeRemove"
                  :limit="1"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                  :before-upload="beforeAvatarUpload"
                  :http-request="handlerUpload"
                >
                  <el-button size="small" icon="el-icon-document" type="primary"
                    >点击上传</el-button
                  >

                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件, 且不超过1MB
                  </div>
                </el-upload>
              </el-col>
              <el-col :span="12">
                <img
                  :src="
                    editForm.blackgroundImg
                      ? apiUrl.split(':')[0] +
                        ':' +
                        apiUrl.split(':')[1] +
                        '/' +
                        editForm.blackgroundImg
                      : ''
                  "
                  class="avatar"
                />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label-width="100px" prop="blackgroundImg">
            <el-input v-model="editForm.blackgroundImg" disabled></el-input>
          </el-form-item>
        </div>

        <el-form-item label="菜单URL" prop="path" label-width="100px">
          <el-input v-model="editForm.path" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="菜单组件" prop="component" label-width="100px">
          <el-input v-model="editForm.component" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="type" label-width="100px">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="0">目录</el-radio>
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum" label-width="100px">
          <el-input-number v-model="editForm.orderNum" :min="1" label="排序号"
            >1</el-input-number
          >
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('editForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- icon 弹框 -->
    <el-dialog title="提示" :visible.sync="dialogIconVisible" width="80%">
      <!-- 使用组件 -->
      <iconList @mainIconClick="iconClick"></iconList>

      <router-link to=""></router-link>
    </el-dialog>
  </div>
</template>

<script>
import iconList from "../../components/iconList";
export default {
  name: "AppMenu",
  data() {
    return {
      dialogVisible: false,
      dialogIconVisible: false,
      editForm: {
        blackgroundColor: "#409EFF",
      },
      editFormRules: {
        parentId: [
          { required: true, message: "请选择上级菜单", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        perms: [{ required: true, message: "请输入权限编码", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        orderNum: [
          { required: true, message: "请填入排序号", trigger: "change" },
        ],
        statu: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      tableData: [],
      backgroundType: true,
      fileList: [],
      oldblackgroundImg: "",
      apiUrl: "",
    };
  },
  created() {
    this.getAppMenuTree();
    this.apiUrl = this.$axios.defaults.baseURL;
  },
  components: {
    iconList,
  },
  methods: {
    getAppMenuTree() {
      this.$axios.get("/sysmenuapp/list").then((res) => {
        this.tableData = res.data.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let blackgroundImgT =this.editForm.blackgroundImg;
          this.editForm[
            !this.backgroundType ? "blackgroundColor" : "blackgroundImg"
          ] = "";
          this.$axios
            .post(
              "/sysmenuapp/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getAppMenuTree();
                },
              });
              if (
                this.oldblackgroundImg &&
                this.oldblackgroundImg != this.editForm.blackgroundImg
              ) {
                this.clearFile(this.oldblackgroundImg);
                this.oldblackgroundImg = "";
              }
              if(this.backgroundType){
                this.clearFile(blackgroundImgT);
              }
              this.$refs[formName].resetFields();
              this.dialogVisible = false;
              this.editForm = {};
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get("/sysmenuapp/info/" + id).then((res) => {
        this.editForm = res.data.data;
        this.fileList = [];
        this.backgroundType = this.editForm.blackgroundColor ? true : false;
        this.dialogVisible = true;
      });
    },
    resetForm(formName) {
      //修改情况 添加图片未保存
      if (
        this.editForm.blackgroundImg &&
        this.oldblackgroundImg != this.editForm.blackgroundImg
      ) {
        this.clearFile(this.editForm.blackgroundImg);
        this.oldblackgroundImg = "";
      }
      //新增情况  添加图片未保存
      if (!this.editForm.id && this.editForm.blackgroundImg) {
        this.clearFile(this.editForm.blackgroundImg);
      }

      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {
        blackgroundColor: "#409EFF",
      };
    },
    handleClose() {
      this.resetForm("editForm");
    },
    delHandle(id) {
      this.$axios.post("/sysmenuapp/delete/" + id).then((res) => {
        this.$message({
          showClose: true,
          message: "恭喜你，操作成功",
          type: "success",
          onClose: () => {
            this.getAppMenuTree();
          },
        });
      });
    },
    herfIconUrl(Event) {
      this.dialogIconVisible = true;
    },
    iconClick(iconClass) {
      this.editForm.icon = iconClass;
      this.dialogIconVisible = false;
    },
    //上传图片配置
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isType) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式！");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }

      return isType && isLt1M;
    },
    handleExceed(files) {
      this.$message.warning(`每次只能选择1个文件`);
    },
    beforeRemove(file) {
      if (
        (file.raw.type === "image/jpeg" || file.raw.type === "image/png") &&
        file.size / 1024 / 1024 < 1
      ) {
        return new Promise((reslove, reject) => {
          this.$confirm(`确定移除 ${file.name}?`).then(() => {
            this.$axios
              .post(
                "/fileoss/delOssFile?filename=" +
                  [
                    this.editForm.blackgroundImg.split("/")[
                      this.editForm.blackgroundImg.split("/").length - 1
                    ],
                  ],
                {}
              )
              .then((res) => {
                if (res.data.code == 200) {
                  this.editForm.blackgroundImg = "";
                  this.$forceUpdate();
                  reslove();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          });
        });
      }
    },
    handlerUpload(param) {
      let fileObject = param.file;
      let formData = new FormData();
      formData.append("file", fileObject);
      this.$axios.post("/fileoss/uploadOssFile", formData).then((res) => {
        if (res.data.data.startsWith("OK")) {
          if (this.editForm.blackgroundImg) {
            this.oldblackgroundImg = this.editForm.blackgroundImg;
          }
          this.editForm.blackgroundImg = res.data.data.split(":")[1];
          this.$forceUpdate();
        }
      });
    },
    clearFile(blackgroundImg) {
      if (blackgroundImg) {
        this.$axios
          .post(
            "/fileoss/delOssFile?filename=" +
              blackgroundImg.split("/")[blackgroundImg.split("/").length - 1]
          )
          .then((res) => {});
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 208px;
  height: 128px;
}
.el-upload {
  text-align: left;
  width: 100%;
}
</style>